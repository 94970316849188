import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';

function App(): React.ReactElement {
  const [age, setAge] = useState<number>(0);

  const getAge = (birthDateString: string): number => {
    const today = new Date();
    const birthDate = new Date(birthDateString);

    const yearsDifference = today.getFullYear() - birthDate.getFullYear();

    if (
      today.getMonth() < birthDate.getMonth() ||
      (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())
    ) {
      return yearsDifference - 1;
    }

    return yearsDifference;
  };

  useEffect(() => {
    setAge(getAge('1999-07-18'));
  }, []);
  return (
    <div className="App">
      <aside>
        <span className="number">1</span>
        <span className="number">2</span>
        <span className="number">3</span>
        <span className="number">4</span>
        <span className="number">5</span>
        <span className="number">6</span>
        <span className="number">7</span>
        <span className="number">8</span>
        <span className="number">9</span>
        <span className="number">10</span>
        <span className="number">11</span>
        <span className="number">12</span>
        <span className="number">13</span>
        <span className="number">14</span>
        <span className="number">15</span>
        <span className="number">16</span>
        <span className="number">17</span>
        <span className="number">18</span>
      </aside>
      <main className="jsonContent">
        <span className="commentLine">{`// ${age} years old`}</span>
        <span className="commentLine">{'// https://leunardo.dev'}</span>
        <br />
        <span className="default">{'{'}</span>
        <div className="block">
          <strong className="key">{'"fullName"'}</strong>
          <strong className="default">:</strong>
          <strong className="string">{'"Leonardo Almeida"'}</strong>
          <strong className="default">,</strong>
        </div>
        <div className="block">
          <strong className="key">{'"location"'}</strong>
          <strong className="default">:</strong>
          <strong className="string">{'"Brazil, Sao Paulo"'}</strong>
          <strong className="default">,</strong>
        </div>
        <div className="block">
          <strong className="key">{'"yearsOfExperience"'}</strong>
          <strong className="default">:</strong>
          <strong className="integer">{Number(format(new Date(), 'yyyy')) - 2016}</strong>
          <strong className="default">,</strong>
        </div>
        <div className="block">
          <strong className="key">{'"age"'}</strong>
          <strong className="default">:</strong>
          <strong className="integer">{age}</strong>
          <strong className="default">,</strong>
        </div>
        <div className="block">
          <strong className="key">{'"email"'}</strong>
          <strong className="default">:</strong>
          <a href="mailto:leo@webid.net.br" className="string">
            {'"leo@webid.net.br"'}
          </a>
          <strong className="default">,</strong>
        </div>
        <div className="block">
          <strong className="key">{'"lookingForAJob"'}</strong>
          <strong className="default">:</strong>
          <strong className="default">{'{'}</strong>
          <div className="block">
            <strong className="key">{'"fullTime"'}</strong>
            <strong className="default">:</strong>
            <strong className="boolean">{'false'}</strong>
            <strong className="default">,</strong>
          </div>
          <div className="block">
            <strong className="key">{'"freelance"'}</strong>
            <strong className="default">:</strong>
            <strong className="boolean">{'true'}</strong>
          </div>
          <strong className="default">{'}'}</strong>
        </div>
        <div className="block">
          <strong className="key">{'"linkedIn"'}</strong>
          <strong className="default">:</strong>
          <a href="https://www.linkedin.com/in/leonardoalmeida99/" className="string">
            {'"https://www.linkedin.com/in/leonardoalmeida99/"'}
          </a>
          <strong className="default">,</strong>
        </div>
        <div className="block">
          <strong className="key">{'"gitHub"'}</strong>
          <strong className="default">:</strong>
          <a href="https://github.com/LeuAlmeida" className="string">
            {'"https://github.com/LeuAlmeida"'}
          </a>
          <strong className="default">,</strong>
        </div>
        <div className="block">
          <strong className="key">{'"stack"'}</strong>
          <strong className="default">:</strong>
          <strong className="string">{'"MERN"'}</strong>
          <strong className="default">,</strong>
        </div>
        <div className="block">
          <strong className="key">{'"currentPosition"'}</strong>
          <strong className="default">:</strong>
          <strong className="string">
            {'"Senior Software Engineering Lead at Hospital Israelita Albert Einstein"'}
          </strong>
        </div>
        <span className="default">{'}'}</span>
      </main>
    </div>
  );
}

export default App;
